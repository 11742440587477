import about from "./languages/pages/about";
import aml from "./languages/pages/aml";
import banking from "./languages/pages/banking";
import bitcoin from "./languages/pages/bitcoin";
import bonus from "./languages/pages/bonus";
import cookie from "./languages/pages/cookie";
import faq from "./languages/pages/faq";
import privacy from "./languages/pages/privacy";
import responsible from "./languages/pages/responsible";
import security from "./languages/pages/security";
import support from "./languages/pages/support";
import terms from "./languages/pages/terms";
import vip from "./languages/pages/vip";

// about.en.title = "Merge Works ( ͡° ͜ʖ ͡°)"

export const casino_contents = {
    about,
    aml,
    banking,
    bitcoin,
    bonus,
    cookie,
    faq,
    privacy,
    responsible,
    security,
    support,
    terms,
    vip,
}
support.en.sections[2].content = ["France : (+33) (0)1 789 00368", "Spain : (+34) 91 290 1420", "International :  (+1) 205 797 6962"]
support.de.sections[2].content = ["Frankreich (+33) (0)1 789 00368", "Spanien (+34) 91 290 1420", "International (+1) 205 797 6962"]
support.fr.sections[2].content = ["France (+33) (0)1 789 00368", "Espagne(+34) 91 290 1420", "International (+1) 205 797 6962"]
support.es.sections[2].content = ["Francia (+33) (0)1 789 00368", "España (+34) 91 290 1420", "Internacional (+1) 205 797 6962"]
support.it.sections[2].content = ["Francia (+33) (0)1 789 00368", "Spagna (+34) 91 290 1420", "Internazionali (+1) 205 797 6962"]

export const promos = {
    ////////////////////////////////////////////////////////////////////////////////

    bonus_slider_data: {
        en: ["/brand/images/bonus_images/1.gif"],
        de: ["/brand/images/bonus_images/1de.gif"],
        fr: ["/brand/images/bonus_images/1fr.gif"],
        it: ["/brand/images/bonus_images/1it.gif"],
        es: ["/brand/images/bonus_images/1es.gif"],
    },
    ////////////////////////////////////////////////////////////////////////////////
    promo_data: [
        {
            category: 1,
            name: {
                en: "Promotion Info",
                de: "Promotion Info",
                es: "Información De La Promoción",
                fr: "Promotion Info",
                it: 'Bonus',
            },


            bonus_details: {
                en: "200% Welcome Bonus up to €2000",
                de: "200% Willkommensbonus bis zu 2000€",
                fr: "200 % bonus de bienvenue jusqu'à 2000€",
                es: "200% bono de bienvenida hasta €2000",
                it: '200% bonus di benvenuto fino a €2000'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>200% Welcome Bonus up to €2000</h2>
                    <p>Do you like to play Slots, Keno and Scratch
                        cards? This bonus is made for you! Get a 200%
                        sign up bonus on your first deposit up to €2000.</p>
                    <p>To boost your bankroll, <a href="/instantplay">click here</a> to launch the game lobby.
                        Register your account and go to the cashier page
                        within the software. Click on claim a promotion
                        and select the welcome bonus. Make the deposit and
                        you will automatically be credited 200% of your
                        deposit amount.</p>
                    Click <a href="/terms">here</a> for the terms and conditions of this bonus. </>,
                de: <>
                    <p>Spielen Sie gerne Slots, Keno oder Gewinnkarten??? Dann ist dieser Bonus wie für Sie gemacht!
                        Denn Sie erhalten bei Ihrer ersten Einzahlung einen 200% Willkommensbonus von bis zu 2000€.</p>
                    <p>Registrieren Sie Ihr Konto und gehen Sie auf die Kassenseite innerhalb der Software. Klicken Sie
                        auf eine Promotion einfordern und wählen Sie den Willkommensbonus.</p>
                    Klicken Sie <a href="/terms">hier</a> für die AGB's des Willkommensbonus.</>,
                fr: <><h2>200 % bonus de bienvenue jusqu'à 2000€</h2>
                    <p>Vous aimez jouer machines à sous, Keno et cartes à gratter? Ce bonus est fait pour vous! Obtenez
                        un bonus d'inscription de 200 % jusqu'à 2000€ sur votre premier dépôt pour augmenter vos fonds.
                        Cliquez <a href="/instantplay">ici</a> et téléchargez le casino maintenant.</p>
                    <p>Enregistrez votre compte et allez à la page de la caisse dans le logiciel. Cliquez sur obtenir
                        une promotion et sélectionnez le bonus de bienvenue.</p>
                    Cliquez <a href="/terms">ici</a> pour les termes et conditions de ce bonus </>,
                es: <><h2>200% bono de bienvenida hasta €2000</h2>
                    <p>¿Te gusta jugar los Slots, Keno y Tarjetas de rascar? ¡Este bono está hecho para ti! Recibe un
                        200%
                        de bono en tu primer depósito de hasta €2000. Para aumentar tu saldo, haz clic <a
                            href="/instantplay">aquí</a> y comienza el juego ahora.</p>
                    <p>Registra tu cuenta y ve a la página del cajero. Haga clic en "activar una promoción" y selecciona
                        el bono de bienvenida.</p>
                    Haga clic <a href="/terms">aquí</a> para ver los términos y condiciones de este bono. </>,
                it: <><h2>200% bonus di benvenuto fino a €2000</h2>
                    <p>Vi piace giocare alle Slots, Keno e Gratta e Vinci? Questo bonus è fatto per voi! Ottenete un
                        bonus di iscrizione di 200% sul vostro primo deposito fino a €2000 per aumentare il vostro
                        banco. cliccate qui e scaricate il casino ora.</p>
                    <p>Registrate il vostro conto e andate nella pagina del cassiere che si trova nel programma stesso.
                        Cliccate su 'ottenere una promozione' e selezionate il bonus di benvenuto.</p>
                    Cliccate qui per i termini e condizioni di questo bonus.Cliccate qui per i termini e condizioni di
                    questo bonus.f this bonus. </>,
            }
        },
        {
            category: 1,
            name: {
                en: "Promotion Info",
                de: "Promotion Info",
                es: "Información De La Promoción",
                fr: "Promotion Info",
                it: 'Bonus',
            },

            bonus_details: {
                en: "100% Cashback Insurance for Table Games",
                de: "100% Geld-zurück Versicherung für Spieltische",
                fr: "100 % d'Assurance Cashback pour Jeux de Table",
                es: "100% Cashback en Juegos de Mesa",
                it: '100% Assicurazione Cashback per Giochi da Tavola'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>100% Cashback Insurance for Table Games</h2>
                    <p>Your first deposit at Rockbet Casino will be matched with either a 200% Welcome bonus for
                        slots games or 100% Cashback Insurance for table games.</p>
                    <p>If you wish to play a table game, simply speak with a Live chat support agent and tell him you
                        wish to take the 100% Cashback Insurance for your first deposit. Once he have noted your
                        request, go ahead and make the deposit. Play with your funds and if the house wins, you are
                        entitled to 100% cashback of your deposit amount. Just tell the support agents you now want the
                        cashback and they will gladly add the money to your balance.</p>
                </>,
                de: <>
                    <p>Für Ihre erste Einzahlung bei der Rockbet Spielbank können Sie entweder mit einem 200%
                        Willkommensbonus für Spielautomaten oder aber mit einer 100% Geld-zurück Versicherung für
                        Spieltische durchstarten.</p>
                    <p>Wenn Sie gerne an Spieltischen Spaß haben, sprechen Sie einfach per Live Chat mit einem unserer
                        Kundenservicemitarbeiter und teilen Sie ihm mit, dass Sie gerne bei Ihrer ersten Einzahlung mit
                        einer 100% Geld-zurück Versicherung für Spieltische haben möchten. Nachdem unser Mitarbeiter
                        Ihre Anfrage erhalten hat, können Sie ihre Einzahlung tätigen und zu spielen beginnen. Wenn das
                        Haus gewinnt, erhalten Sie 100% Ihres Ersteinzahlungsbetrags zurück. Sagen Sie einfach unserem
                        Kundenservice, dass Sie Ihre Rückzahlung jetzt möchten und diese werden Ihnen das Geld auf Ihrem
                        Spielkonto gutschreiben.</p>
                </>,
                fr: <>
                    <h2>100 % d'Assurance Cashback pour Jeux de Table</h2>
                    <p>Votre premier dépôt sur Casino Rockbet sera jumelé avec un Bonus de Bienvenue de 200 %
                        valable sur les jeux de machines à sous ou 100 % d'Assurance Cashback valable également sur les
                        jeux de table.</p>
                    <p>Si vous souhaitez jouer à un jeu de table, contactez un agent du chat en direct et dites-lui que
                        vous souhaitez recevoir l'Assurance Cashback de 100% sur votre premier dépôt. Une fois qu'ils
                        auront pris note de votre demande, vous n'aurez plus qu'a faire le dépôt. Jouez avec vos propres
                        fonds et si la maison gagne, vous pourrez bénéficier de 100 % de cashback du montant de votre
                        dépôt. Il vous suffira juste de dire a l'agent de soutien que vous le voulez maintenant et ils
                        se feront un plaisir d'ajouter de l'argent à votre solde.</p>
                </>,
                es: <><h2>Puntos Comp</h2>
                    <p>Todos aquellos jugadores que depositen en Rockbet Casino, podrán obtener puntos de
                        bonificación por las apuestas realizadas en dinero real.</p>
                    <p>Para ser elegible, se debe realizar al menos 1 depósito en el casino. Todos los juegos tienen la
                        misma tarifa de puntos que es: 1 punto de comp por cada €1 apostado - 100 000 puntos comp pueden
                        ser convertidos en €100.</p>
                </>,
                it: <><h2>100% Assicurazione Cashback per Giochi da Tavola</h2>
                    <p>Il tuo primo deposito nel Casino Rockbet verra' abinato con 200% bonus di benvenuto per le
                        slot o un'assicurazione del 100% cashback per i giochi da tavola. Se desideri giocare con i
                        giochi da tavola, semplicemente parla con gli agenti del supponto nella chat dal vivo e dirgli
                        che vorresti prendere l'assicurazione del 100% per il tuo primo deposito. Una volta che loro
                        avranno notato la tua richiesta, procedi con il deposito. Gioca con i tuoi fondi e se la casa
                        vince, hai diritto al 100% indietro dell'importo del tuo deposito. Devi solo dire agli agenti
                        del supporto che vuoi ora il cashback e loro saranno lieti di aggiungere i soldi nel tuo
                        saldo.</p>
                </>,
            }
        },
        {
            category: 1,
            name: {
                en: "Promotion Info",
                de: "Promotion Info",
                es: "Información De La Promoción",
                fr: "Promotion Info",
                it: 'Bonus',
            },

            bonus_details: {
                en: "Loyalty Program - Casino Comp Points",
                de: "Gratispunkte für Ihre Treue",
                fr: "Points comp du casino",
                es: '100% Cashback en Juegos de Mesa',
                it: 'Punti Comps Casino'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>Loyalty Program - Casino Comp Points</h2>
                    <p>All depositing players at Rockbet Casino get comp points on real money wagers . To be
                        eligible a player must make at least 1 deposit to the casino to qualify. All games have the same
                        comp points rate which is 1 comp point per $1 wagered where 100000 comp points can be converted
                        into €100.</p>
                </>,

                de: <>
                    <p>Alle Spieler, die bei Rockbet Geld einzahlen, erhalten Treuepunkte, sog. Comp Punkte auf
                        ihre Wetten mit Echtgeld. Um sich dafür zu qualifizieren, muss ein Spieler mindestens eine
                        Einzahlung in der Spielbank geleistet haben. Für alle Spiele gilt die gleiche Gratispunktquote
                        von einem Gratispunkt pro 1€ Wetteinsatz. z.B. 100000 gesammelte Treuepunkte können in 100€
                        umgewandelt werden.</p>
                </>,
                fr: <>
                    <h2>Points comp du casino</h2>
                    <p>Tous les joueurs qui déposent de l'argent sur leur compte au Casino Rockbet perçoivent des
                        points 'comp' échangeables contre de l'argent réel. Pour bénéficier de cette offre, les joueurs
                        devront avoir procédé au moins une fois à un dépôt sur leur compte. Tous les jeux offrent le
                        même nombre de points comp, c'est-à-dire 1 point comp pour tous les 1€ misé. Les joueurs peuvent
                        échanger 100000 points comp contre 100€.</p>
                </>,
                es: <><h2>100% Cashback en Juegos de Mesa</h2>
                    <p>Su primer depósito en el Casino Rockbet será con el bono de bienvenida de 200% para los
                        juegos de tragamonedas o 100% Cashback para los juegos de mesa.</p>
                    <p>Si usted desea jugar un juego de mesa, simplemente ingrese al Chat en Línea, hable con un agente
                        de soporte y dígale que usted desea tomar el 100% Cashback para su primer depósito. Una vez que
                        se ha tomado nota de su solicitud, siga adelante y realice el depósito.</p>
                    <p>Juegue con su balance y si la casa gana, usted tiene derecho a reclamar reembolso del 100% de su
                        depósito. Solo contacte a un agente de soporte y dígale que desea su reembolso, con gusto
                        agregará el dinero a su cuenta.</p>
                </>,
                it: <><h2>Punti Comps Casino</h2>
                    <p>Tutti i giocatori depositanti a Rockbet ricevranno sulle scommesse effettuate con soldi
                        reali, dei punti comps. Per potersi qualificare un giocatore deve effettuare almeno un deposito
                        all'interno del casinò. Tutti i giochi hanno lo stesso tassodi punti comps che equivale a 1
                        punto comp per ogni 1€ scommesso, e 100000 potranno per esempio essere convertiti a 100€.</p>
                </>,
            }
        },

    ],
    ////////////////////////////////////////////////////////////////////////////////
    jackpot: [
        {
            name: "Jim S. won",
            value: "$11,910.14",
            desc: "on at the Strike Gold "
        },
        {
            name: "Eve G. won",
            value: "$1.733.00",
            desc: "on at the copa "
        },
        {
            name: "Jordan W. won ",
            value: "$5,235.60",
            desc: "on at the Major Moolah "
        },
        {
            name: "John H. won",
            value: "$856.00",
            desc: "on at the Catsino "
        },
        {
            name: "Pam T. won ",
            value: "$29,990.58",
            desc: "on at the Money Magic"
        },
    ]
    ////////////////////////////////////////////////////////////////////////////////
}

export default casino_contents





